import { useEffect, useState } from 'react';
import './Question.css';
import { Camera } from '../Camera/Camera';
import { CheckBox } from './OptionTypes';
import { QuestionSubMenu } from './QuestionSubMenu';
import { Bubbles } from './Bubble';
import './Bubble.css';
import { QUESTION_STATUS } from '../../utils/constants';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import Draggable from 'react-draggable';
import { Focus } from './TabFocus';
import Typography from '@mui/material/Typography';
import { RightClickMenu } from './RightClickMenu';
import { QUESTIONS_SAMPLE_DATA } from '../Dashboard/data';
import { useTestSessionContext } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { stopPrediction } from '../../utils/ai';

const MAX_QUE = QUESTIONS_SAMPLE_DATA.length;

export const QuestionPage = () => {
  const [progress, setProgress] = useState(0);
  const [contextMenu, setContextMenu] = useState(null);

  const navigate = useNavigate();
  const [questions, setQuestions] = useState(QUESTIONS_SAMPLE_DATA);
  const [current, setCurrent] = useState(0);
  const [state, dispatch] = useTestSessionContext();

  const { status, text } = QUESTIONS_SAMPLE_DATA[current];
  let choices = QUESTIONS_SAMPLE_DATA[current].choices;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = 0.1 * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log(state);
    switch (state.currentSelectedOption) {
      case null:
        if (state.currentSelectedOption === null) {
          choices.forEach((c, i, arr) => {
            arr[i].isActive = false;
          });
        }
        break;
      case 'a':
        selectOption('a');
        break;
      case 'b':
        selectOption('b');
        break;
      case 'c':
        selectOption('c');
        break;
      case 'd':
        selectOption('d');
        break;
      case 'next':
        handleNext();
        break;
    }
  }, [state]);

  const selectOption = (ltr) => {
    const choiceIndex = CHOICE_LABELS.indexOf(ltr);
    choices.forEach((c, i, arr) => {
      arr[i].isActive = i === choiceIndex ? true : false;
    });
  };
  const toggleOption = (ltr) => {
    const choiceIndex = CHOICE_LABELS.indexOf(ltr);
    choices[choiceIndex].isActive = !choices[choiceIndex].isActive;
  };
  const handleClick = (e) => {
    toggleOption(e.target.id);
  };

  const handleNext = () => {
    // Submit your answer
    // perform next question ops
    if (current === MAX_QUE - 1) return navigate('/exit');
    setCurrent((c) => c + 1);
  };

  const handlePrev = () => {
    // Submit your answer
    // perform next question ops
    if (current > 0) setCurrent((c) => c - 1);
  };

  const handleBubbleClick = (e, number, ref) => {
    // set current question to questionId

    ref.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });

    console.log(number);
    const newStatus = [...questions];
    newStatus[number - 1].status = QUESTION_STATUS.CURRENT;
    newStatus[current - 1].status = QUESTION_STATUS.UNVISITED;
    setQuestions(newStatus);
    setCurrent(number);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleExit = () => {
    stopPrediction();
    navigate('/exit');
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <Typography component="div">
        <Focus />
        <ProgressBar progress={progress} />
        <QuestionSubMenu total={MAX_QUE} ok={10} review={10} skipped={10} handleExit={handleExit} />
        <Bubbles questions={questions} handleBubbleClick={handleBubbleClick} />

        <div className="ques_body row">
          <div className="question column">
            <Question text={text} number={current + 1} />

            <div className="res-navigate">
              <div className="checkbox">
                <span className="box">
                  <input className="check" type="checkbox" />
                </span>
                <span className="markForReview">Mark For Review</span> <br />
              </div>

              <div className="navbtn">
                <button className="previous"> &#60; Previous </button>
                <button className="next" onClick={handleNext}>
                  Next &#62;
                </button>
              </div>
            </div>
          </div>

          <div className="question_background column">
            <div className="rollNumber">0211856</div>
            <Choices choices={choices} handleClick={handleClick} />
            <div className="navigate">
              <div className="checkbox">
                <span className="box">
                  <input className="check" type="checkbox" />
                </span>
                <span className="markForReview">Mark For Review</span> <br />
              </div>

              <div className="navbtn">
                <button className="previous" onClick={handlePrev}>
                  &#60; Previous
                </button>
                <button className="next" onClick={handleNext}>
                  Next &#62;
                </button>
              </div>
            </div>
          </div>
        </div>
        <CameraContainer />
      </Typography>
      <RightClickMenu contextMenu={contextMenu} setContextMenu={setContextMenu} />
    </div>
  );
};

const Question = ({ text, number }) => {
  return <h1 className="question">{`${number}: ${text}`}</h1>;
};

const ProgressBar = ({ progress }) => (
  <Box sx={{ width: '100%' }}>
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{ color: '#00D1FF', zIndex: '-1' }}
    />
  </Box>
);

const CameraContainer = () => (
  <Draggable bounds="html">
    <div className="cameraBox">
      <Camera />
    </div>
  </Draggable>
);

const CHOICE_LABELS = ['a', 'b', 'c', 'd', 'e'];

const Choices = ({ choices, handleClick }) => {
  return choices.map(({ text, isActive }, index) => (
    <CheckBox
      isActive={isActive}
      handleClick={handleClick}
      label={CHOICE_LABELS[index]}
      text={text}
    />
  ));
};

const NavigationButtons = ({ handleNext, handlePrev }) => {};
