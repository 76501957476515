import { Hands } from '@mediapipe/hands';

let hands;
// todo needs processed streams for input
// width: 1280,
// height: 720,

export const initHandDetectionAlgo = async (resultHandler) => {
  if (hands && hands.close) return hands.close;
  if (hands) return;

  hands = new Hands({
    locateFile: (file) => {
      console.log(`https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`);
      return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
    },
  });

  hands.initialize();

  const modelConfig = {
    maxNumHands: 1,
    modelComplexity: 1,
    minDetectionConfidence: 0.65,
    minTrackingConfidence: 0.5,
  };

  hands.setOptions(modelConfig);
  hands.onResults(resultHandler || console.log);

  console.log('Loaded mediapipe/hands');
  return true;
};

export const predictionFunctionHandDetectionAlgo = async (videoElement) => {
  await hands.send({ image: videoElement });
};

export const closeHandDetectionAlgo = () => {
  if (hands && hands.close) hands.close();
};
