import CircularProgress from '@mui/material/CircularProgress';
import { errorToast } from '../../utils/toast';
import './Loader.css';

export const Loader = ({ fullscreen, error }) => {
  if (error) errorToast(error);
  return (
    <div className={`loader ${fullscreen ? 'loader-fullscreen' : ''}`}>
      <CircularProgress className="circle" />
    </div>
  );
};
