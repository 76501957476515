import { useEffect, useState } from 'react';
import { CardContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { TESTS_SAMPLE_DATA } from './data';
import { strcmp } from '../../helpers/strcmp';

export const SearchBox = ({ setFilteredData }) => {
  const [wordEntered, setWordEntered] = useState('');

  const handleClearButton = () => setWordEntered('');

  useEffect(() => {
    if (wordEntered == '') return setFilteredData(TESTS_SAMPLE_DATA);
    if (wordEntered.length < 3) return;
    let matchedFilter = [];
    for (let i of TESTS_SAMPLE_DATA) {
      if (
        strcmp(i.test, wordEntered) ||
        strcmp(i.subject, wordEntered) ||
        strcmp(i.code, wordEntered)
      ) {
        matchedFilter.push(i);
      }
    }
    setFilteredData(matchedFilter);
  }, [wordEntered]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
  };

  return (
    <div className="search">
      <div className="searchdiv">
        <div className="searchInputs">
          &nbsp;&nbsp;
          <SearchIcon className="searchicon" />
          <input
            className="input"
            type="text"
            placeholder="Search"
            value={wordEntered}
            onChange={handleFilter}
          />
          {/* <div className="icondiv">
            <CardContent >
              <ClearIcon onClick={handleClearButton} className="searchicon" />
            </CardContent>
          </div> */}
        </div>
      </div>
    </div>
  );
};
