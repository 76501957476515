import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useState } from 'react';
import './Announcements.css';
import './MediaCard.css';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';

export const Announcements = () => {
  const [value, setValue] = useState(new Date());
  return (
    <Card className="cardd">
      <CardContent>
        <input type="text" placeholder="Heading" className="head" />
        <div className="detai">
          <div>
            <span style={{ display: 'inline', color: 'white' }}>Topic: </span>{' '}
            <input type="text" className="subjec" />
          </div>

          <div style={{ marginTop: '10%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputClass="custom-input"
                label="Date:"
                inputFormat="MM/DD/YYYY"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    className="myDatePicker"
                    sx={{
                      svg: { color: '#FFFFFF' },
                      input: {
                        color: '#FFFFFF',
                        width: '90px',
                        height: '10px',
                        marginTop: '10px',
                        fontFamily: 'Garamond',
                      },
                      label: {
                        color: '#FFFFFF',
                        fontSize: '20px',
                        fontWeight: 400,
                        fontFamily: 'Garamond',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          {/* <div>
              <h4 style={{ display: 'inline', color: 'white' }}>Date: </h4>{' '}
              <input type="text" className="subjec" />
            </div> */}
          <div>
            <span style={{ display: 'inline', color: 'white' }}>Detail: </span>{' '}
            <input type="text" className="subjec" />
          </div>
        </div>
        <div className="adddiv">
          <button className="add">ADD</button>
        </div>
      </CardContent>
    </Card>
  );
};
