import React, { useCallback, useRef } from 'react';
import './Configuration.css';
import CamPic from '../../assets/camPic.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { useSharedVideoConstraints } from '../../hooks/videoConstraintsHook';
import { initAlgos, startPrediction } from '../../utils/ai';
import { errorToast, promiseToast, warningToast } from '../../utils/toast';
import { useLoaderHook } from '../../hooks/useLoaderHook';

export const Configuration = () => {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [videoConstraints, setVideoConstraints] = useSharedVideoConstraints();
  const [modelsInit, setModelsInit] = useState(false);
  const videoElement = useRef(null);

  useEffect(() => {
    promiseToast(
      initAlgos,
      {
        loading: 'Models Loading ...',
        success: 'Models Loaded, you can proceed.',
        error: 'Someting went wrong(models not loaded).',
      },
      (status) => setModelsInit(status)
    );
  }, []);

  // useEffect(() => {
  //   // TODO: Remove latter
  //   if (modelsInit) startPrediction(videoElement.current.video);
  // }, [modelsInit]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const d = mediaDevices.filter(({ kind }) => kind === 'videoinput');
      setDevices(d);
      if (!isMobile)
        setVideoConstraints((constraints) => {
          return { ...constraints, deviceId: d[0] };
        });
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const handleVideoSelectChange = (e) => {
    setVideoConstraints((constraints) => {
      return { ...constraints, deviceId: e.target.value };
    });
  };

  const handleProceedButton = () => {
    if (!modelsInit) return warningToast('Ai model(s) not loaded, Please wait.');
    navigate('/waiting');
  };

  return (
    <div>
      <h1 className="heading">Camera Mic Configuration</h1>
      <Webcam
        className="cameraConfig"
        mirrored="false"
        onUserMediaError={console.error}
        audio={false}
        videoConstraints={videoConstraints}
        ref={videoElement}
      />

      <div className="align">
        {!isMobile && (
          <div className="camButton">
            <img className="camPic" src={CamPic} alt="camPic" />
            <select className="camText" name="Camera" onChange={handleVideoSelectChange}>
              {devices.map((device, index) => (
                <option id={index} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <button className="proceedConfig" onClick={handleProceedButton}>
        Proceed
      </button>
    </div>
  );
};
