import { getAuthToken } from './Authentication';
import axios from 'axios';
import { BACKEND_URL } from './constants';
import { errorToast } from './toast';

const getCommonHeaders = async () => ({
  headers: {
    token: await getAuthToken(),
  },
});

export const getDashboard = async () => {
  try {
    const response = await axios.get(BACKEND_URL + '/dashboard/get-data', await getCommonHeaders());
    return response.data;
  } catch (err) {
    apiErrorHandler(err);
  }
};

const apiErrorHandler = (err) => {
  const { response } = err || {};
  const { data } = response || {};

  if (response) console.error(err.response);
  if (data) return errorToast(data.error);

  errorToast('Someting went wrong.');

  // Temporarily not throwing errors uncomment when handling errors form UI
  // throw err;
};
