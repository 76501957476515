import React, { useContext, useEffect, useState } from 'react';
import login_logo from '../../assets/login_logo.jpg';
import './Login.css';
//For Modals Testing
import { ModalProvider, useModal } from 'react-hooks-use-modal';
import { errorToast } from '../../utils/toast';

import { EVENTS, sampleQue, useTestSessionContext } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getDashboard } from '../../utils/api';
import { useFormInput } from '../../hooks/useFormInputHook';
import { Loader } from '../Loader/Loader';

export const Login = () => {
  const email = useFormInput('');
  const password = useFormInput('');

  const [state, dispatch] = useTestSessionContext();

  const navigate = useNavigate();

  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });

  const submitForm = (e) => {
    e.preventDefault(); // To prevent auto refresh
  };

  const handleLoginButtonClick = () => {
    // return loginWithRedirect();
    navigate('/instruction');
  };
  const handleNotifyClick = () => {
    errorToast();
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <div className="login">
      <Loader />
      <img className="login_logo" src={login_logo} alt="Login Logo"></img>
      <h1 className="userlogin">User Login</h1>
      <form className="formWrapper" action="" onSubmit={submitForm}>
        <div>
          <label htmlFor="email" />
          <input
            className="user-details"
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            {...email}
          />
        </div>
        <div>
          <label htmlFor="password" />
          <input
            className="user-details"
            placeholder="Password"
            type="password"
            name="password"
            id="password"
            autoComplete="off"
            {...password}
          />
        </div>
      </form>
      <div className="buttonWrapper">
        <button type="submit" className="loginbutton" onClick={handleLoginButtonClick}>
          <span className="loginText">Login</span>
        </button>
        <button className="loginbutton" onClick={handleNotifyClick}>
          Notify!
        </button>
        <button
          className="loginbutton"
          onClick={() => dispatch({ type: 'set', payload: sampleQue })}
        >
          dispatch
        </button>
        <div>
          <button
            className="loginbutton"
            onClick={() => {
              console.log('test');
              console.log(openModal());
              window.test = openModal;
            }}
          >
            Modal
          </button>
        </div>
        <button
          className="loginbutton"
          onClick={() => {
            getDashboard().then(console.log);
          }}
        >
          axios
        </button>
      </div>
      <Modal>
        <div className="modal">
          <h1>Title</h1>
          <h2 className="h2">
            Are you sure you <br />
            you want to leave
          </h2>
          <div className="buttondiv">
            <button className="modalbutton" onClick={closeModal}>
              CLOSE
            </button>
            <button className="modalbutton2">STAY</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Login;
