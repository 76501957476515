import './Button.css';

export const CheckBox = ({ isActive, handleClick, label, text }) => {
  return (
    <div className="checkBox">
      <button
        id={label}
        onClick={handleClick}
        // option
        className={`option-button ${isActive ? 'active' : ''}`}
      >
        <span className={`option-label ${isActive ? 'active' : ''}`}>{label.toUpperCase()}</span>{' '}
        {text}{' '}
      </button>
    </div>
  );
};
