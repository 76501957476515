import { ToastContainer } from 'react-toastify';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';

import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { QuestionPage } from './components/Question/Question';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage/ErrorPage';
import { Login } from './components/Login/Login';
import { Instructions } from './components/Instructions/Instructions';
import { Configuration } from './components/Configuration/Configuration';
import { WaitingPage } from './components/Waiting/Waiting';
import { Header } from './components/Header/Header';
import { Exit } from './components/ExitPage/ExitPage';
import { Dashboard } from './components/Dashboard/Dashboard';
import { ProtectedRouteElement } from './components/ProtectedRouter/ProtectedRoutes';
import { CookieConcentContainer } from './components/CookieConcentContainer/CookieConcentContainer';
import { auth } from './utils/Authentication';
import { TestSessionContextProvider } from './hooks/redux';
import { useEffect } from 'react';

const App = () => {
  const [user, loading, error] = useSignInWithGoogle(auth);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') console.log(user);
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Oops... {error.message}</div>;

  return (
    <BrowserRouter>
      <TestSessionContextProvider>
        <Header />
        <div className="site">
          <Routes>
            <Route path="/login" element={<ProtectedRouteElement component={Login} />} />
            <Route path="/dashboard" element={<ProtectedRouteElement component={Dashboard} />} />
            <Route
              path="/instruction"
              element={<ProtectedRouteElement component={Instructions} />}
            />
            <Route
              path="/configuration"
              element={<ProtectedRouteElement component={Configuration} />}
            />
            <Route path="/question" element={<ProtectedRouteElement component={QuestionPage} />} />
            <Route path="/waiting" element={<ProtectedRouteElement component={WaitingPage} />} />
            <Route path="/exit" element={<ProtectedRouteElement component={Exit} />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <ToastContainer />
        <CookieConcentContainer />
      </TestSessionContextProvider>
    </BrowserRouter>
  );
};

export default App;
