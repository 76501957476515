import { useState } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import Mediacard from './MediaCard';
import vectorCalendar from '../../assets/vectorCalendar.svg';
import { Announcements } from './Announcements';
import CampaignIcon from '@mui/icons-material/Campaign';

export const Calender = () => {
  const [date, setDate] = useState(new Date());
  return (
    <div className="calendar-container">
      {/* <img src={vectorCalendar} className="vectorCalendar"></img> */}
      <Calendar onChange={setDate} value={date} className="react-calendar" />

      <h1 className="announcement">
        <h1 className="announcement-text">Announcements</h1>
        <CampaignIcon style={{ fontSize: '50px' }} />
      </h1>
      <br />
      <div className="mediacards">
        <Announcements />
        <Mediacard />
        <Mediacard />
        <Mediacard />
      </div>
    </div>
  );
};
