import React, { useState, useEffect } from 'react';
import './AccountHandle.css';
import profilePic from '../../assets/profile.svg';
import helpPic from '../../assets/help.svg';
import samplePic from '../../assets/sample.svg';
import settingPic from '../../assets/settings.svg';
import logoutPic from '../../assets/logout.svg';
import { logout, auth, signInWithGoogleRedirect } from '../../utils/Authentication';
import { useAuthState } from 'react-firebase-hooks/auth';

export const Account = () => {
  const [isActive, setIsActive] = useState(false);
  const [accountMenu, setToggleMenu] = useState(false);
  const [user] = useAuthState(auth);

  const personalInfo = (e) => {
    setIsActive((current) => !current);
    setToggleMenu(!accountMenu);
  };

  const logoutHandler = () => {
    logout();
  };

  return user ? (
    <div className="accountHandle">
      <button className={`personal_details ${isActive ? `active` : `null`}`} onClick={personalInfo}>
        <img className="topCam" src={user.photoURL} alt="topCamImg" />
        <span className="name">{user.displayName}</span>
      </button>

      {accountMenu && (
        <div className="account">
          <button className="profile non_active">
            {/* TODO: Put SVG in different file */}
            <div className="vector"></div>
            <img className="icons" src={profilePic} alt="profilePic"></img>
            <span className="account_text">Profile</span>
          </button>

          <button className="help non_active">
            <div className="vector"></div>
            <img className="icons" src={helpPic} alt="helpPic"></img>
            <span className="account_text">Help & Support</span>
          </button>

          <button className="sample non_active">
            <div className="vector"></div>
            <img className="icons" src={samplePic} alt="samplePic"></img>
            <span className="account_text">Sample Test</span>
          </button>

          <button className="settings non_active">
            <div className="vector"></div>
            <img className="icons" src={settingPic} alt="settingPic"></img>
            <span className="account_text">System Settings</span>
          </button>

          <hr className="horizontal_bar" />

          <button className="non_active logout">
            <div className="vector"></div>
            <img className="icons" src={logoutPic} alt="logoutPic"></img>
            <span className="account_text" onClick={logoutHandler}>
              Logout
            </span>
          </button>
        </div>
      )}
    </div>
  ) : (
    // TODO: Styling
    <div className="accountHandle">
      <button className="personal_details active" onClick={signInWithGoogleRedirect}>
        <span className="sign-in-button">Sign In</span>
      </button>
    </div>
  );
};
