import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { firebaseConfig } from './constants';

import { errorToast } from './toast';

const app = firebase.initializeApp(firebaseConfig);
const googleProvider = new firebase.auth.GoogleAuthProvider();

export const auth = app.auth();

export const signInWithGooglePopup = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    // Uncomment while debuging
    // const user = res.user;
    // console.log(user);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

export const signInWithGoogleRedirect = async () => {
  try {
    const res = await auth.signInWithRedirect(googleProvider);
    // Uncomment while debuging
    // const user = res.user;
    // console.log(user);
  } catch (err) {
    console.error(err);
    errorToast(err.message);
  }
};

export const logout = async () => {
  await auth.signOut();
};

export const getCurrentuser = () => app.auth().currentUser;

export const getAuthToken = () =>
  new Promise((resolve, reject) => {
    app.auth().currentUser.getIdToken().then(resolve).catch(reject);
  });
