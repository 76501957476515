import { useState } from 'react';
import { useBetween } from 'use-between';

const useVideoConstraints = () => {
  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: 'user',
  });
  return [videoConstraints, setVideoConstraints];
};

export const useSharedVideoConstraints = () => useBetween(useVideoConstraints);
