import { REDUX_ACTIONS, store } from '../../hooks/redux';
import {
  AI_DISTANCE_ERROR_RATE,
  AI_EVENT_TRIGRING_THRESHOLD,
  AI_PROCESSING_FPS,
} from '../constants';
import { errorToast, infoToast, warningToast } from '../toast';
import { initFaceDetectionAlgo, predictionFunctionFaceDetectionAlgo } from './face';
import {
  initHandDetectionAlgo,
  predictionFunctionHandDetectionAlgo,
  closeHandDetectionAlgo,
} from './hand';
import { getCounter } from './helpers/counter';
import { countFaces, getFaceDistance } from './helpers/face';
import { countFingers, HAND_GESTURES, simpleHandGesture } from './helpers/fingers';
import { initHuman, predictionFunctionHuman } from './human';

export let init = false;
let predictionEventLoop;

const handGestureCounter = getCounter();
const faceDetectionCounter = getCounter();
const distanceCounter = getCounter();

export const initAlgos = async () => {
  try {
    if (init) return true;
    // init algos
    const res = await Promise.all([
      // initFaceDetectionAlgo(faceResultHandler),
      initHandDetectionAlgo(handResultHandler),
      initHuman(humanResultHandler),
    ]);

    for (let status of res) if (!status) return status;
    init = true;
    return true;
  } catch (err) {
    errorToast('Some unknown error, pelase refresh the page');
  }
};

// frame = video ref
export const startPrediction = (videoElement) => {
  if (!videoElement) throw new Error('NO VIDEO ELEMENT TO PROCESS');
  predictionEventLoop = setInterval(sendFrameForPrediction, 1000 / AI_PROCESSING_FPS, videoElement);
};

export const stopPrediction = () => {
  clearInterval(predictionEventLoop);
  closeHandDetectionAlgo();
};

const sendFrameForPrediction = (frame) => {
  if (!frame) throw new Error('NO FRAME TO PROCESS');
  // code to send each frame for prediction by each model
  // predictionFunctionFaceDetectionAlgo(frame);
  predictionFunctionHandDetectionAlgo(frame);
  predictionFunctionHuman(frame);
};

const faceResultHandler = (result) => {
  const faces = countFaces(result);
  const distance = getFaceDistance(result);

  faceDetectionCounter.inc(faces, AI_EVENT_TRIGRING_THRESHOLD / 2, () => {
    if (faces > 1) warningToast('Multiple face detected');
    if (!faces) warningToast('Face not detected');

    console.log(faces);
  });

  distanceCounter.inc(
    distance,
    AI_EVENT_TRIGRING_THRESHOLD / 4,
    () => {
      console.log('Counter => \t', distance);
    },
    AI_DISTANCE_ERROR_RATE
  );

  console.log(distance);
};

const handResultHandler = (result) => {
  if (!result) return;
  // const fingers = countFingers(result)
  // if (!fingers && fingers !== 0) return;

  // c1.inc(fingers, AI_EVENT_TRIGRING_THRESHOLD, () => {
  //     if (fingers === -1) return
  //     if (fingers === 5) return infoToast(`Thums up 👍🏻`)
  //     if (fingers === 0) return infoToast(`Box 👊`)
  //     infoToast(`Selected ${fingers} option.`)
  //     console.log(fingers)
  // })
  // console.log('                   ', fingers)

  const gesture = simpleHandGesture(result);
  handGestureCounter.inc(gesture, AI_EVENT_TRIGRING_THRESHOLD, () => {
    if (!HAND_GESTURES[gesture]) return;
    infoToast(`Selected ${gesture} option.`);
    store.dispatch({ type: REDUX_ACTIONS.SET_CURRENT_OPTION, payload: gesture });
  });
};

const humanResultHandler = (result) => {
  const faces = result.face.length;
  // console.log(result);
  // console.log(faces);
  faceDetectionCounter.inc(faces, AI_EVENT_TRIGRING_THRESHOLD / 2, () => {
    if (faces > 1) warningToast('Multiple face detected');
    if (!faces) warningToast('Face not detected');
  });
};
