import { useRef } from 'react';
import { QUESTION_STATUS } from '../../utils/constants';
import './Bubble.css';

export const Bubble = ({
  number,
  status = QUESTION_STATUS.UNVISITED,
  activeQueBubbleRef,
  onClick,
}) => {
  const ref = useRef(null);
  return (
    <div
      id="test"
      className="bubbleBox"
      title={status}
      ref={ref}
      // ref={status === QUESTION_STATUS.CURRENT ? activeQueBubbleRef : null}
      onClick={(e) => onClick(e, number, ref)}
    >
      <div className={`circle  ${status.toLowerCase() || ''}`}>{parseInt(number || 0)}</div>
    </div>
  );
};

export const Bubbles = ({ questions, handleBubbleClick }) => {
  return (
    <div className="bubbleBackground">
      {questions.map(({ status }, index) => (
        <Bubble number={index + 1} key={index} status={status} onClick={handleBubbleClick} />
      ))}
    </div>
  );
};
