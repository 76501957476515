import WarningIcon from '@mui/icons-material/Warning';
import Cards from './Cards';

export const TestsList = ({ filteredData }) => {
  return (
    <div className="rightside">
      {filteredData.length > 0 ? (
        filteredData?.map((e, index) => {
          return (
            <div className="upcard" key={index}>
              <Cards
                subject={e.subject}
                test={e.test}
                code={e.code}
                // start={e.start}
                // time={e.time}
                startDate="05/4/23"
                time="3-Hour"
                college="College of Technology and Engineering, Udaipur , Rajasthan ,
                India, Asia"
                day="Today"
              />
            </div>
          );
        })
      ) : (
        <div className="nothingFound">
          <WarningIcon /> &nbsp; Opps, Nothing found !!
        </div>
      )}
    </div>
  );
};
