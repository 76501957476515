import React, { useRef, useState } from 'react';
import './Instructions.css';
import { useNavigate } from 'react-router-dom';
// import { useQuestions } from "../../hooks/redux";

export const Instructions = () => {
  const navigate = useNavigate();
  const handleProceedButton = () => {
    navigate('/configuration');
  };

  const [readingProgress, setReadingProgress] = useState(0);
  const reference = useRef(null);

  const scrollProgress = () => {
    const winScroll = reference.current.scrollTop;
    const height = reference.current.scrollHeight - reference.current.clientHeight;
    if (winScroll === 0) {
      return setReadingProgress(0);
    }
    if (winScroll > height) {
      return setReadingProgress(100);
    }
    setReadingProgress((winScroll / height) * 100);
  };

  return (
    <div>
      <div className="instruction_body" onScroll={scrollProgress} ref={reference}>
        <div className="instructions">
          <h1 className="heading1">SEMESTER EXAMINATION</h1>
          <h1 className="heading2">General instructions</h1>

          <div className="instructionstext">
            <InstructionText />
          </div>
        </div>
        <button className="proceed" onClick={handleProceedButton}>
          Proceed to next Page
        </button>
      </div>

      <div className="progress">
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${readingProgress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

const InstructionText = () => (
  <ol type="1">
    <li>
      Students are required to Login at least 15 minutes before the start of Examinations to ensure
      course enrollment.
    </li>

    <li>Students must have stable internet connection preferably with UPS backup</li>

    <li>Webcam is mandatory. Without camera examination will not start.</li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Students are required to Login at least 15 minutes before the start of Examinations to ensure
      course enrollment.
    </li>

    <li>Students must have stable internet connection preferably with UPS backup</li>

    <li>Webcam is mandatory. Without camera examination will not start.</li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Students are required to Login at least 15 minutes before the start of Examinations to ensure
      course enrollment.
    </li>

    <li>Students must have stable internet connection preferably with UPS backup</li>

    <li>Webcam is mandatory. Without camera examination will not start.</li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Students are required to Login at least 15 minutes before the start of Examinations to ensure
      course enrollment.
    </li>

    <li>Students must have stable internet connection preferably with UPS backup</li>

    <li>Webcam is mandatory. Without camera examination will not start.</li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Students are required to Login at least 15 minutes before the start of Examinations to ensure
      course enrollment.
    </li>

    <li>Students must have stable internet connection preferably with UPS backup</li>

    <li>Webcam is mandatory. Without camera examination will not start.</li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>

    <li>
      Student must have peaceful environment and No one should be around the students at the time of
      Examinations. If anyone else found around the student, the examination of the student will not
      be considered valid and stand cancelled.
    </li>
  </ol>
);

export default Instructions;
