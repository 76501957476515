import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import './ExitPage.css';

export const Exit = () => {
  const navigate = useNavigate();
  const handleExitButton = () => {
    navigate('/dashboard');
  };

  const value = 25;

  const addActiveClass = (e) => {
    const clicked = e.target.id;
    if (this.state.active === clicked) {
      this.setState({ active: '' });
    } else {
      this.setState({ active: clicked });
    }
  };

  return (
    <div className="exitPage">
      <div class="circle-loader">
        <div class="checkmark draw"></div>
      </div>
      <div className="exitBody">
        <span className="exitText">
          Thank You for submitting your response. Your response has been forwarded. We hope you had
          a good examination 🤞🏻.
        </span>
      </div>

      <div
        className={`scoreRed score ${value <= 30 ? 'active' : 'not-active'}`}
        onLoad={addActiveClass}
      >
        {value}
      </div>
      <div
        className={`scoreYellow score ${value <= 75 && value > 30 ? 'active' : 'not-active'}`}
        onLoad={addActiveClass}
      >
        {value}
      </div>
      <div
        className={`scoreGreen score ${value >= 76 ? 'active' : 'not-active'}`}
        onLoad={addActiveClass}
      >
        {value}
      </div>
      <div className="responseBtn">
        <button className="viewScore sameTraits">View Score</button>
        {isMobile && <br />}
        <button className="responses sameTraits">Marked Responses</button>
        {isMobile && <br />}
        <button className="exit sameTraits" onClick={handleExitButton}>
          Exit
        </button>
      </div>
    </div>
  );
};
