import * as React from 'react';
import Card from '@mui/material/Card';
import './MediaCard.css';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import TaskIcon from '@mui/icons-material/Task';
export default function Mediacard() {
  const isDeclared = true;
  return (
    <Card className="cardd">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="white">
          <div className="icon-text">
            <span>{isDeclared ? <TaskIcon /> : <StickyNote2Icon />}</span>
            Next Text
          </div>
        </Typography>
        <Typography className="title">
          Mathematics 1 <br />
          <div className="titleclass">
            <p>firday 14/10/2022</p>
            <p>code:3468</p>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
}
