import React, { useState, useEffect } from 'react';
import { Calender } from './Calendar';
import { TESTS_SAMPLE_DATA } from './data';
import './Dashboard.css';
import { TestsList } from './TestsList';
import { SearchBox } from './Search';
import Settings from '../../assets/Settings.png';
import Reset from '../../assets/Reset.png';
import Add from '../../assets/Add.png';

export const Dashboard = () => {
  const [filteredData, setFilteredData] = useState(TESTS_SAMPLE_DATA);
  return (
    <div className="grid-container">
      <div className="calendar-container">
        <Calender />
      </div>

      <div className="grid-filtered-data">
        <div class="text-icon-flex-container">
          <div class="test-knowledge text-icon">Start Testing Your Knowledge...</div>
          <img src={Reset} className="icon" />
          <img src={Add} className="icon" />
          <SearchBox setFilteredData={setFilteredData} />
        </div>

        <TestsList filteredData={filteredData} />
      </div>
    </div>
  );
};
