export const getCounter = () => {
  const counter = () => {
    let count = 0;
    let startValue;

    const inc = (value, max, cb, tolerableErrorPercent = 0) => {
      if (!count) startValue = value;
      if (errorPercent(value, startValue) > tolerableErrorPercent) return (count = 0);
      if (count === max) {
        count = 0;
        return cb();
      }
      count++;
    };
    return { inc, count };
  };

  return counter();
};

const errorPercent = (actual, expected) => Math.abs(((expected - actual) / expected) * 100);
