import { QUESTION_STATUS } from '../../utils/constants';

export const TESTS_SAMPLE_DATA = [
  {
    test: 'B Tech Third year 2022-23',
    subject: 'Mathematics 1',
    code: 'BS-101',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 3',
    subject: 'chemistry',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 2',
    subject: 'chemistry',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 2',
    subject: 'chemistry',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'B Tech Third year 2022-23 Electronics and Telecomminucation Engineering',
    subject: 'Electronics and Telecomminucation',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 892',
    subject: 'chemistry',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 61',
    subject: 'physics',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 2',
    subject: 'chemistry',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
  {
    test: 'Test 32',
    subject: 'maths',
    code: 'code:3468',
    start: 'start date: 05/04/23',
    end: 'end date : 16/04/23',
    time: 'timing:9:00AM to 9:00PM',
  },
];

export const QUESTIONS_SAMPLE_DATA = [
  {
    text: 'What is list ?',
    choices: [{ text: '[]' }, { text: '{}' }, { text: '...' }, { text: '!!!' }],
    status: QUESTION_STATUS.UNVISITED,
    typeOfQuestion: '',
    img: '',
  },
  {
    text: 'What is String ?',
    choices: [{ text: 'str' }, { text: '1' }, { text: '"1"' }, { text: '!!!' }],
    status: QUESTION_STATUS.UNVISITED,
    typeOfQuestion: '',
    img: '',
  },
  {
    text: 'What is Dictionary ?',
    choices: [{ text: '[]' }, { text: '{}' }, { text: '...' }, { text: '!!!' }],
    status: QUESTION_STATUS.UNVISITED,
    typeOfQuestion: '',
    img: '',
  },
  {
    text: 'What is tupple ?',
    choices: [{ text: '()' }, { text: '{}' }, { text: '...' }, { text: '!!!' }],
    status: QUESTION_STATUS.UNVISITED,
    typeOfQuestion: '',
    img: '',
  },
  {
    text: 'What is set integer ?',
    choices: [{ text: 'str' }, { text: '1' }, { text: '"1"' }, { text: '!!!' }],
    status: QUESTION_STATUS.UNVISITED,
    typeOfQuestion: '',
    img: '',
  },
];
