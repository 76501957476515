import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import './Cards.css';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import cardIcon from '../../assets/cardIcon.svg';
import { useModal } from 'react-hooks-use-modal';
import TearOffCalendar from '../../assets/Tear-Off-Calendar.png';
import Clock from '../../assets/clock.png';
import PlayBtn from '../../assets/playpause.png';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Rightside(props) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const navigate = useNavigate();
  const start = () => navigate('/instruction');
  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });
  const modal = () => {};

  return (
    <>
      <Card className="cards">
        <CardContent style={{ padding: '7px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white" className="test">
            <div className="testico">
              <div>
                <img src={cardIcon} height="20px"></img>
              </div>
              <div>{props.test}</div>
            </div>
            <hr className="line" />
          </Typography>

          <Typography className="title">
            <div className="college-text">{props.college}</div>
            <div className="flex-container">
              <div className="card-item">
                <img src={TearOffCalendar} />
                <span>Start Date:{props.startDate}</span>
              </div>
              <div className="titleclass">
                <span>
                  Subject: {props.subject}({props.code})
                </span>
              </div>
              <div className="card-item">
                <img src={Clock} />
                <span>Duration:{props.time}</span>
              </div>
              <div className={props.day === 'Today' ? 'today-day-item' : 'tomorrow-day-item'}>
                <span>{props.day}</span>
              </div>
            </div>
          </Typography>

          <div className="polygon">
            <img src={PlayBtn} />
          </div>
        </CardContent>

        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            className="expand"
          >
            <ExpandMoreIcon className="expand" />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography className="title">
            <div className="datas">
              <p>
                {props.start}
                <br />
                {props.end} <br />
                {props.time}
              </p>
            </div>
          </Typography>
          <CardActions>
            <div className="middlebuttondiv">
              <button className="middlebutton" onClick={openModal}>
                RESCHEDULE TEST
              </button>
              <button className="middlebutton2" onClick={start}>
                START TEST
              </button>
            </div>
          </CardActions>
        </Collapse>
      </Card>
      <Modal>
        <div className="modals">
          <div className="i">
            <div className="ico io">
              <img className="icon" src={cardIcon}></img>
            </div>
            <div className="ico oi">
              <button onClick={closeModal} style={{ background: 'transparent', border: 'none' }}>
                <img className="icon" src={cardIcon}></img>
              </button>
            </div>
          </div>
          <br />
          <br />
          <div className="contents">
            <div className="sid">
              <input type="text" placeholder="TEST NAME" className="tesname" />
              <br />
              <br />
              <div className="detai">
                <div>
                  <h3 style={{ display: 'inline' }}>Subject: </h3>{' '}
                  <input type="text" placeholder="subject" className="subject" />
                </div>
                <br />
                <div>
                  <h3 style={{ display: 'inline' }}>Start Date:</h3>{' '}
                  <input type="text" placeholder="stsrt date" className="subject" />
                </div>
                <br />
                <div>
                  <h3 style={{ display: 'inline' }}>End Date: </h3>{' '}
                  <input type="text" placeholder="end date" className="subject" />
                </div>
                <br />
                <div>
                  <h3 style={{ display: 'inline' }}>Timing: </h3>
                </div>
              </div>
            </div>
            <div className="sid">
              <input type="text" placeholder="TEST NAME" className="tesname" />
              <br />
              <br />
              <div>
                <h3>code:3264</h3>
              </div>
            </div>
          </div>
          <div className="modalbutto">
            <button className="middlebutton butt">content</button>
            <button className="middlebutton sche">SCHEDULE TEST</button>
          </div>
        </div>
      </Modal>
    </>
  );
}
