import { createContext, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { HAND_GESTURES } from '../utils/ai/helpers/fingers';
// import { socket } from '../utils/socket-io-client';

export const store = {
  isReady: false,
  dispatch: () => console.error('store is NOT ready'),
  navigate: () => console.error('store is NOT ready'),
};

// socket.on('connect', function () {
//   console.log('Connected to Server');
// });

// // message listener from server
// socket.on('newMessage', function (message) {
//   console.log(message);
// });

// // emits message from user side
// socket.emit('createMessage', {
//   to: 'john@ds',
//   text: 'what kjkljd',
// });

// // when disconnected from server
// socket.on('disconnect', function () {
//   console.log('Disconnect from server');
// });

export const sampleQue = [
  { text: `quesiton 2`, id: 102 },
  { text: `quesiton 1`, id: 101 },
];

export const initialState = {
  que: [],
  dCount: 0,
  currentSelectedOption: null,
};

export const REDUX_ACTIONS = {
  GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST',
  NEXT: 'NEXT',
  PREV: 'PREV',
  SUBMIT: 'SUBMIT',
  MARK_FOR_REVIEW: 'MARK_FOR_REVIEW',
  MARK_VISITED: 'MARK_VISITED',
  SEND_DATA_FRAME: 'SEND_DATA_FRAME',
  MARK_PENALTY: 'MARK_VISITED',
  TIME_UP: 'TIME_UP',
  DISQUALIFY: 'DISQUALIFY',
  CLOCK_SYNC: 'CLOCK_SYNC',
  FINISH_TEST: 'FINISH_TEST',
  ERROR_LOG: 'ERROR_LOG',
  SET_CURRENT_OPTION: 'SET_CURRENT_OPTION',
};

export const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case 'set':
      return { que: action.payload, dCount: state.dCount + 1 };
    case REDUX_ACTIONS.SET_CURRENT_OPTION:
      return { ...state, currentSelectedOption: gestureToOption[action.payload] };
    default:
      throw new Error();
  }
};
export const TestSessionContext = createContext();

export const useTestSessionContext = () => useContext(TestSessionContext);

// usage
// const [state, dispatch] = useTestSessionContext();

export const TestSessionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // if (!store.isReady) {
  //   store.isReady = true;
  //   store.dispatch = (params) => dispatch(params);
  //   store.navigate = (params) => navigate(params);
  //   // store.dispatch = (...params) => dispatch(...params);
  //   // store.navigate = (...params) => navigate(...params);
  //   Object.freeze(store);
  // }

  return (
    <TestSessionContext.Provider value={[state, dispatch]}>{children}</TestSessionContext.Provider>
  );
};

const gestureToOption = {
  // ONE: 'a',
  // TWO: 'b',
  // THREE: 'c',
  // FOUR: 'd',
};
gestureToOption[HAND_GESTURES.ONE] = 'a';
gestureToOption[HAND_GESTURES.TWO] = 'b';
gestureToOption[HAND_GESTURES.THREE] = 'c';
gestureToOption[HAND_GESTURES.FOUR] = 'd';
gestureToOption[HAND_GESTURES.OK] = 'next';

window.test = store;
