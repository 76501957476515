import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const RightClickMenu = ({ contextMenu, setContextMenu }) => {
  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Menu
      className="menu"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'transparent',
        },
      }}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }
    >
      <MenuItem onClick={handleClose}>Finish Test</MenuItem>
      <MenuItem onClick={handleClose}>Help</MenuItem>
      <MenuItem onClick={handleClose}>Support</MenuItem>
      <MenuItem onClick={handleClose}>Hide/Show Camera</MenuItem>
    </Menu>
  );
};
