import { useState } from 'react';

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    setValue('');
  };

  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };

  // Declaring
  // const name = useFormInput("");
  // const email = useFormInput("");

  // Reset Form
  // name.onReset();
  // email.onReset();

  // usage
  // <Input placeholder="Enter Name" {...name} required />
  // <Input placeholder="Enter Email" {...email} type="email" required />
};
