import React, { useEffect } from 'react';
import './ErrorPage.css';
import ErrorGif from '../../assets/GIF_404_Error.gif';
import { useNavigate } from 'react-router-dom';

import { auth, signInWithGoogleRedirect } from '../../utils/Authentication';
import { useAuthState } from 'react-firebase-hooks/auth';

export const ErrorPage = () => {
  const navigate = useNavigate();

  // const [user, loading, error] = useAuthState(auth);
  // useEffect(() => {
  //   if (user) navigate('/dashboard');
  // }, [user]);

  return (
    <div className="errorVideoWrapper">
      <img className="errorGif" src={ErrorGif} alt="Sorry, your browser doesn't support GIFs." />
      <br />
      <button onClick={() => navigate('/dashboard')} className="back">
        Back to Home
      </button>
      <button onClick={signInWithGoogleRedirect} className="back">
        Sign In
      </button>
    </div>
  );
};
