import React, { useState, useEffect } from 'react';
import './Header.css';

export const DateTime = () => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <h1 className="time-header">
      <p>{date.toLocaleTimeString()}</p>
    </h1>
  );
};

export default DateTime;
