import React from 'react';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import '../../App.css';
import { auth } from '../../utils/Authentication';
import { Loader } from '../Loader/Loader';

export const NotLoggedIn = () => {
  return <Navigate to="/error" />;
};

export const ProtectedRouteElement = ({ component: RouteComponent, ...passProps }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();

  if (error) return <Loader fullScreen error />;
  else if (loading) return <Loader fullScreen />;
  else if (user)
    return (
      <div className="content">
        <RouteComponent {...passProps} location={location} navigate={navigate} />
      </div>
    );

  return <NotLoggedIn />;
};
