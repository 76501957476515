export const QUESTION_STATUS = {
  CURRENT: 'CURRENT',
  VISITED: 'VISITED',
  MARKEDREVIEW: 'MARKEDREVIEW',
  ANSWERED: 'ANSWERED',
  UNVISITED: 'UNVISITED',
};

export const AI_PROCESSING_FPS = 4; // Frames per second
export const AI_EVENT_TRIGRING_THRESHOLD = 2 * AI_PROCESSING_FPS; // Seconds * fps = frames
export const AI_DISTANCE_ERROR_RATE = 5; // Error rate in % which is tolerable

export const BACKEND_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/api'
    : `${window.location.origin}/api`;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
