import './Header.css';
import TexamLogo from '../../assets/Texam-logo.png';
import { Account } from '../Account/AccountHandle';
import { useNavigate } from 'react-router-dom';
import DateTime from './DateTime';
import { store, useTestSessionContext } from '../../hooks/redux';

export const Header = () => {
  const navigate = useNavigate();
  const handleHomeNavigation = () => navigate('/dashboard');

  const [state, dispatch] = useTestSessionContext();

  if (!store.isReady) {
    store.isReady = true;
    store.dispatch = dispatch;
    store.navigate = (...params) => navigate(...params);
    Object.freeze(store);
  }

  return (
    <div className="site-header">
      <div className="navbar">
        <img
          className="res-logo"
          src={TexamLogo}
          alt="TexamLogoImage"
          onClick={handleHomeNavigation}
        />
        <DateTime />
        <Account />
      </div>
    </div>
  );
};
