const config = {
  debug: false,
  modelBasePath: 'https://cdn.jsdelivr.net/npm/@vladmandic/human/models',
  face: {
    enabled: true,
    detector: {
      return: true,
      rotation: true,
      maxDetected: 50,
      iouThreshold: 0.01,
      minConfidence: 0.4,
    },
    mesh: { enabled: false },
    iris: { enabled: false },
    emotion: { enabled: true },
    description: { enabled: true },
  },
  body: { enabled: true },
  hand: { enabled: true },
  object: { enabled: true },
};

let human;
let resHandler;

const log = (...msg) => console.log('[HUMAN LOG] ', ...msg);
const status = (...msg) => console.log('[HUMAN STATUS] ', ...msg);

export const initHuman = async (resultHandler = console.log) => {
  const H = await import('@vladmandic/human');
  human = new H.default(config);
  log('human version:', human.version, '| tfjs version:', human.tf.version['tfjs-core']);
  log('platform:', human.env.platform, '| agent:', human.env.agent);
  status('loading models...');
  await human.load(); // preload all models
  log('backend:', human.tf.getBackend(), '| available:', human.env.backends);
  log('loaded models:' + Object.values(human.models).filter((model) => model !== null).length);
  status('initializing...');
  await human.warmup(); // warmup function to initialize backend for future faster detection
  log('initialized');
  resHandler = resultHandler;
  return true;
};

export const predictionFunctionHuman = async (videoElement) => {
  if (!human) return;
  await human.detect(videoElement); // actual detection; were not capturing output in a local variable as it can also be reached via this.human.result
  const interpolated = human.next(human.result); // smoothen result using last-known results
  resHandler(interpolated);
};

// const userConfig = {
//   backend: 'humangl',
//   async: true,
//   warmup: 'none',
//   cacheSensitivity: 0,
//   debug: true,
//   modelBasePath: '../../models/',
//   deallocate: true,
//   filter: {
//     enabled: true,
//     equalization: true,
//     width: 0,
//   },
//   face: {
//     enabled: true,
//     detector: { return: true, rotation: true, maxDetected: 50, iouThreshold: 0.01, minConfidence: 0.2 },
//     mesh: { enabled: true },
//     iris: { enabled: false },
//     emotion: { enabled: true },
//     description: { enabled: true },
//   },
//   hand: { enabled: false },
//   gesture: { enabled: false },
//   body: { enabled: false },
//   segmentation: { enabled: false },
// };

// let userConfig = {
// face: { enabled: false },
// body: { enabled: false },
// hand: { enabled: false },
/*
  warmup: 'none',
  backend: 'webgl',
  debug: true,
  wasmPath: 'https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@3.9.0/dist/',
  async: false,
  cacheSensitivity: 0.75,
  filter: { enabled: false, flip: false },
  face: { enabled: false,
    detector: { return: false, rotation: true },
    mesh: { enabled: false },
    iris: { enabled: false },
    description: { enabled: false },
    emotion: { enabled: false },
  },
  object: { enabled: false },
  gesture: { enabled: true },
  hand: { enabled: true, maxDetected: 1, minConfidence: 0.5, detector: { modelPath: 'handtrack.json' } },
  body: { enabled: false },
  // body: { enabled: true, modelPath: 'movenet-multipose.json' },
  segmentation: { enabled: false },
  */
/*
  face: { iris: { enabled: false }, emotion: { enabled: false } },
  hand: { enabled: false },
  body: { enabled: false },
  gesture: { enabled: false },
  */
// };
