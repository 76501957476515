import CookieConsent from 'react-cookie-consent';

export const CookieConcentContainer = () => {
  return (
    <CookieConsent
      buttonStyle={{
        fontWeight: '400',
        fontSize: '20px',
      }}
      contentClasses="cookie-concent-content"
      overlay
      // Uncomment while debugging
      debug={
        process.env.REACT_APP_DISABLE_COOKIE_CONCENT !== 'true' &&
        process.env.NODE_ENV !== 'production'
      }
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};
